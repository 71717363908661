import React, { Component } from "react";
import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Rotate = styled.div`
  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 25px;
    height: 25px;
    margin-top: -20px;
    margin-left: -20px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: ${props => props.theme.onSecondarySurface};
    border-bottom-color: ${props => props.theme.onSecondarySurface};
    animation: ${rotate360} 0.8s ease infinite;
  }
`;

export default class Spinner extends Component {
  render() {
    return <Rotate {...this.props} />;
  }
}
