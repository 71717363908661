import React, { useState } from "react";

export const NavContext = React.createContext();

const NavStateProvider = props => {
  const [menuState, setMenuState] = useState(true);
  const toggleMenu = () => {
    setMenuState(!menuState);
  };

  return (
    <NavContext.Provider
      value={{
        toggleMenu,
        menuState
      }}
    >
      {props.children}
    </NavContext.Provider>
  );
};

export default NavStateProvider;
