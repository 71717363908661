import "./App.css";
import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import AuthContext from "./components/Auth/AuthContext";
import NavStateProvider from "./components/Layout/NavStateProvider";
import MainContainer from "./components/Layout/MainContainer";
import Nav from "./components/Nav";
import ContentPage from "./pages/ContentPage";

const history = createBrowserHistory();

function App() {
  return (
    <Router history={history}>
      <AuthContext history={history}>
        <NavStateProvider>
          <MainContainer>
            <Nav />
            <main>
              <ContentPage />
            </main>
          </MainContainer>
        </NavStateProvider>
      </AuthContext>
    </Router>
  );
}

export default App;
