import React, { useContext } from "react";
import styled from "styled-components/macro";
import { AuthContext } from "../Auth/AuthContext";

const Container = styled.div`
  display: flex;
  background: ${props => props.theme.background};
  @media (min-width: 768px) {
    display: flex;
  }
  body.home-page & {
    background: transparent;
  }
`;

export default props => {
  const { user } = useContext(AuthContext);
  return <Container hasUser={user}>{props.children}</Container>;
};
