import React from "react";
import styled from "styled-components/macro";

const CardActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  right: 1em;
  top: 1em;
  margin-left: 1em;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  /* justify-content: space-between; */
  color: ${props => props.theme.onSurface};
  background-color: ${props => props.theme.surface};
  flex: ${props => (props.flex ? props.flex : "1")};
  overflow:  ${props => (props.menuFlyInRight ? "" : "hidden")};
  height: ${props => (props.full ? "100%" : "auto")};
  /* &:hover {
    ${CardActionsContainer} {
      display: flex;
    }
  } */
`;

const CardHeader = styled.div`
  display: flex;
  padding: 1em;
  align-items: center;
  position: relative;
`;

const CardTitleContainer = styled.div`
  flex: 1;
  position: relative;
  width: 100%;
`;

const CardTitle = styled.div`
  text-transform: uppercase;
  font-size: 1.2em;
  color: ${props => props.theme.onSecondarySurface};
  font-weight: bold;
  margin-bottom: 0.2em;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CardTitleDescription = styled.div`
  text-transform: uppercase;
  font-size: 0.8em;
  color: ${props => props.theme.onSurface};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CardActions = styled.div`
  display: flex;
`;

const CardBody = styled.div`
  padding: 1em;
  border-radius: 0 0 2px 2px;
  height: 100%;
`;

export default ({
  title,
  titleDescription,
  body: Body,
  actions: Actions,
  menuFlyInRight,
  full,
  headless
}) => {
  return (
    <Card full={full} flex={2} menuFlyInRight={menuFlyInRight}>
      {!headless && (
        <CardHeader>
          <CardTitleContainer>
            <CardTitle>{title}</CardTitle>
            <CardTitleDescription>{titleDescription}</CardTitleDescription>
          </CardTitleContainer>

          {Actions ? (
            <CardActionsContainer>
              <CardActions>
                <Actions />
              </CardActions>
            </CardActionsContainer>
          ) : null}
        </CardHeader>
      )}
      <CardBody>{Body ? <Body /> : "Nothing to Render"}</CardBody>
    </Card>
  );
};
