import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

export function isTokenValid(tokenExp) {
  const time = Date.now().valueOf() / 1000;
  if (time > tokenExp) {
    return false;
  } else {
    return true;
  }
}

function clearUser() {
  Cookies.remove("token");
}

export function buildUser() {
  const apiToken = Cookies.get("token");
  if (apiToken)
  {
    const decodedApiToken = jwtDecode(apiToken);

    if (decodedApiToken.exp)
    {
      const tokenValid = isTokenValid(decodedApiToken.exp);
      if (tokenValid)
      {
        return { name: decodedApiToken.name };
      }
      else
      {
        clearUser();
        return null;
      }
    }
    else
    {
      clearUser();
      return null;
    }
  }
  else
  {
    clearUser();
    return null;
  }
}
