import React, { useState, useEffect } from "react";
import useProductManagementApi from "../../api/useProductManagementApi";
import Spinner from "../Loaders/Spinner";
import ErrorMessages from "../Notifications/ErrorMessages";
import { Formik, Field, Form } from "formik";
import {
  FormControl,
  StyledField,
  FormActions,
  ErrorMessage,
  StyledSelect,
  Label
} from "./FormControls";
import Button from "../Button";
import SplashLoader from "../Loaders/SplashLoader";
import confirmDlg from './ConfirmDialog.js'

export default props => {
  const { entityId, entityName, entityBaseUrl, customers, subscriptionTemplates } = props;
  const defaultId = "System Generated";

  const [confirmDlgConfig, setConfirmDlgConfig] = useState({
    show: false,
    title: "",
    description: "",
    action: null
  });

  // Setup needed API Hooks
  // If entity ID is provided get entity
  const url = entityId ? `/${entityBaseUrl}/${entityId}` : null;
  const [
    { loading: getLoading, errors: getError, data: getData },
    getApi
  ] = useProductManagementApi(url, "get", null);

  // Used to create a new one
  const [
    { loading: createLoading, errors: createError, data: createData },
    createApi
  ] = useProductManagementApi();

  // Used to delete an existing one
  const [
    { loading: deleteLoading, errors: deleteError, data: deleteData },
    deleteApi
  ] = useProductManagementApi();

  // Used to edit an existing one
  const [
    { loading: updateLoading, errors: updateError, data: updateData },
    updateApi
  ] = useProductManagementApi();

  useEffect(() => {
    // Return to list view if one was deleted, edited or created
    if (deleteData || updateData || createData) {
      props.history.push(`/${entityBaseUrl}s`);
    }
  }, [deleteData, updateData, createData]);

  if (
    entityId &&
    (getLoading ||
      deleteLoading ||
      updateLoading ||
      createLoading)
  ) {
    const loadingMsg = getLoading
      ? `Fetching ${entityName}`
      : deleteLoading
      ? `Deleting ${entityName}`
      : updateLoading
      ? `Updating ${entityName}`
      : `Creating ${entityName}`
    return <SplashLoader text={loadingMsg} />;
  }

  return (
    <>
      {confirmDlg(confirmDlgConfig, setConfirmDlgConfig)}

      <Formik
        enableReinitialize={true}
        initialValues={{
          id: getData ? getData.id : defaultId,
          template: getData && subscriptionTemplates ? subscriptionTemplates.find(st => st.value === getData.templateId ): "",
          customer: getData && customers ? customers.find(c => c.value === getData.customerId) : "",
          name: getData ? getData.name : "",
          dataSourceAllotment: getData ? getData.dataSourceAllotment : "",
          maxAllotment: getData ? getData.maxAllotment : "",
          enhanceCustomerEngagementThreshold: getData
            ? getData.enhanceCustomerEngagementThreshold
            : "",
          extendedAllotmentMaxDuration: getData
            ? getData.extendedAllotmentMaxDuration
            : "",
          cost: getData ? getData.cost : "",
          active: getData ? getData.active : ""
        }}
        validate={values => {
          let errors = {};

          if (!values.name) {
            errors.name = "Required";
          }

          if (!values.template) {
            errors.dataSourceAllotment = "Required";
          }

          if (!values.customer) {
            errors.customer = "Required";
          }

          // Only required if we are editing a subscription
          if(entityId)
          {
            if (!values.dataSourceAllotment) {
              errors.dataSourceAllotment = "Required";
            }

            if (!values.maxAllotment) {
              errors.maxAllotment = "Required";
            }

            if (!values.enhanceCustomerEngagementThreshold) {
              errors.enhanceCustomerEngagementThreshold = "Required";
            }

            if (!values.extendedAllotmentMaxDuration) {
              errors.extendedAllotmentMaxDuration = "Required";
            }

            if (!values.cost) {
              errors.cost = "Required";
            }
          }
          return errors;
        }}
        onSubmit={(values) => {
          if (values.id === defaultId) {
            createApi({
              url: `/${entityBaseUrl}`,
              method: "post",
              body: {
                name: values.name,
                templateId: values.template && values.template.value ? values.template.value : null,
                customerId:
                  values.customer && values.customer.value
                    ? values.customer.value
                    : null,
                dataSourceAllotmentOverride: values.dataSourceAllotment
                  ? values.dataSourceAllotment
                  : null,
                maxAllotmentOverride: values.maxAllotment
                  ? values.maxAllotment
                  : null,
                enhanceCustomerEngagementThresholdOverride: values.enhanceCustomerEngagementThreshold
                  ? values.enhanceCustomerEngagementThreshold
                  : null,
                extendedAllotmentMaxDurationOverride: values.extendedAllotmentMaxDuration
                  ? values.extendedAllotmentMaxDuration
                  : null,
                costOverride: values.cost ? values.cost : null,
              }
            });
          } else {
            updateApi({
              url: `/${entityBaseUrl}/${values.id}`,
              method: 'put',
              body: {
                name: values.name != getData.name ? values.name : null,
                templateId: values.template && values.template.value ? values.template.value : null,
                customerId:
                  values.customer && values.customer.value
                    ? values.customer.value
                    : null,
                dataSourceAllotment: values.dataSourceAllotment
                  ? values.dataSourceAllotment
                  : null,
                maxAllotment: values.maxAllotment
                  ? values.maxAllotment
                  : null,
                enhanceCustomerEngagementThreshold: values.enhanceCustomerEngagementThreshold
                  ? values.enhanceCustomerEngagementThreshold
                  : null,
                extendedAllotmentMaxDuration: values.extendedAllotmentMaxDuration
                  ? values.extendedAllotmentMaxDuration
                  : null,
                cost: values.cost ? values.cost : null,
                active: values.active
              }
            });
          }
        }}
      >
        {({ values, isSubmitting, isValid, setFieldValue, setFieldTouched }) => {
          return (
            <Form>
            {entityId && (
                <FormControl>
                  <StyledField
                    name={`id`}
                    type="text"
                    label="ID"
                    value={values.id}
                    disabled={true}
                  />
                  <ErrorMessage name={`id`} />
                </FormControl>
              )}
              <FormControl>
                <StyledField
                  name={`name`}
                  type="text"
                  label="Name"
                  placeholder="Name"
                  value={values.name}
                />
                <ErrorMessage name={`name`} />
              </FormControl>
              {entityId && (
                <FormControl>
                  <StyledField
                    name={`template`}
                    type="text"
                    label="Subscription Template"
                    value={values.template.label}
                    disabled={true}
                  />
                  <ErrorMessage name={`template`} />
                </FormControl>
              )}
              {!entityId && (
                <FormControl>
                <Label>Template ID</Label>
                <StyledSelect
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`template`}
                  placeholder={`Subscription Template ID`}
                  label="Template ID"
                  value={values.template}
                  options={subscriptionTemplates}
                  onBlur={e => setFieldTouched(`template`, e)}
                  onChange={e => setFieldValue(`template`, e)}
                  menuPortalTarget={document.body}
                />
                <ErrorMessage name={`template`} />
              </FormControl>
              )}
              {entityId && (
                <FormControl>
                  <StyledField
                    name={`customer`}
                    type="text"
                    label="Customer"
                    value={values.customer.label}
                    disabled={true}
                  />
                  <ErrorMessage name={`customer`} />
                </FormControl>
              )}
              {!entityId && (
                <FormControl>
                  <Label>Customer</Label>
                  <StyledSelect
                    className={`react-select-container`}
                    classNamePrefix={`react-select`}
                    name={`customer`}
                    placeholder={`Customer`}
                    label="Customer"
                    value={values.customer}
                    options={customers}
                    onBlur={e => setFieldTouched(`customer`, e)}
                    onChange={e => setFieldValue(`customer`, e)}
                    menuPortalTarget={document.body}
                  />
                  <ErrorMessage name={`customer`} />
                </FormControl>
              )}
              {entityId && (
                <>
                  <FormControl>
                    <StyledField
                      name={`dataSourceAllotment`}
                      type="number"
                      label="Data Source Allotment"
                      placeholder="Data Source Allotment"
                      value={values.dataSourceAllotment}
                    />
                    <ErrorMessage name={`dataSourceAllotment`} />
                  </FormControl>
                  <FormControl>
                    <StyledField
                      name={`maxAllotment`}
                      type="number"
                      label="Max Allotment"
                      placeholder="Max Allotment"
                      value={values.maxAllotment}
                    />
                    <ErrorMessage name={`maxAllotment`} />
                  </FormControl>
                  <FormControl>
                    <StyledField
                      name={`enhanceCustomerEngagementThreshold`}
                      type="number"
                      label="Enhance Customer Engagement Threshold"
                      placeholder="Enhance Customer Engagement Threshold"
                      value={values.enhanceCustomerEngagementThreshold}
                    />
                    <ErrorMessage name={`enhanceCustomerEngagementThreshold`} />
                  </FormControl>
                  <FormControl>
                    <StyledField
                      name={`extendedAllotmentMaxDuration`}
                      type="number"
                      label="Extended Allotment Max Duration"
                      placeholder="Extended Allotment Max Duration"
                      value={values.extendedAllotmentMaxDuration}
                    />
                    <ErrorMessage name={`extendedAllotmentMaxDuration`} />
                  </FormControl>
                  <FormControl>
                    <StyledField
                      name={`cost`}
                      type="number"
                      label="Cost"
                      placeholder="Cost"
                      value={values.cost}
                    />
                    <ErrorMessage name={`cost`} />
                  </FormControl>
                </>)}
                {!entityId && (
                <>
                  <FormControl>
                    <StyledField
                      name={`dataSourceAllotment`}
                      type="number"
                      label="Override Data Source Allotment"
                      placeholder="Override Data Source Allotment"
                      value={values.dataSourceAllotment}
                    />
                    <ErrorMessage name={`dataSourceAllotment`} />
                  </FormControl>
                  <FormControl>
                    <StyledField
                      name={`maxAllotment`}
                      type="number"
                      label="Override Max Allotment"
                      placeholder="Override Max Allotment"
                      value={values.maxAllotment}
                    />
                    <ErrorMessage name={`maxAllotment`} />
                  </FormControl>
                  <FormControl>
                    <StyledField
                      name={`enhanceCustomerEngagementThreshold`}
                      type="number"
                      label="Override Enhance Customer Engagement Threshold"
                      placeholder="Override Enhance Customer Engagement Threshold"
                      value={values.enhanceCustomerEngagementThreshold}
                    />
                    <ErrorMessage name={`enhanceCustomerEngagementThreshold`} />
                  </FormControl>
                  <FormControl>
                    <StyledField
                      name={`extendedAllotmentMaxDuration`}
                      type="number"
                      label="Override Extended Allotment Max Duration"
                      placeholder="Override Extended Allotment Max Duration"
                      value={values.extendedAllotmentMaxDuration}
                    />
                    <ErrorMessage name={`extendedAllotmentMaxDuration`} />
                  </FormControl>
                  <FormControl>
                    <StyledField
                      name={`cost`}
                      type="number"
                      label="Override Cost"
                      placeholder="Override Cost"
                      value={values.cost}
                    />
                    <ErrorMessage name={`cost`} />
                  </FormControl>
                </>)}
              {entityId && (
                <FormControl>
                <label>
                  Active
                  <Field name={`active`} type="checkbox" />
                </label>
              </FormControl>
              )}
              <FormActions>
                <FormControl>
                  {getError || deleteError || createError || updateError ? (
                    <ErrorMessages
                      errors={
                        getError
                        ? getError
                        : deleteError
                        ? deleteError
                        : createError
                        ? createError
                        : updateError
                      }
                    />
                  ) : null}

                  {entityId && (
                    <Button
                      list="true"
                      danger="true"
                      type="button"
                      onClick={() => {
                        setConfirmDlgConfig({
                          show: true,
                          title: `Confirm ${entityName} Removal`,
                          description: `Are you sure you wish to remove this ${entityName}?`,
                          action: () => {
                            deleteApi({
                              url: url,
                              method: "delete",
                              body: null
                            });
                            setConfirmDlgConfig({
                              show: false,
                              title: "",
                              description: "",
                              action: null
                            });
                          }
                        });
                      }}
                    >
                      Remove
                    </Button>
                  )}

                  <Button
                    list="true"
                    danger="true"
                    type="reset"
                  >
                    {entityId ? "Undo" : "Clear"}
                  </Button>

                  <Button type="submit" disabled={(isSubmitting && !getError && !deleteError && !createError && !updateError) || !isValid}>
                    Submit
                  </Button>
                </FormControl>

                {isSubmitting && !getError && !deleteError && !createError && !updateError ? <FormControl><Spinner /></FormControl> : null}
              </FormActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
