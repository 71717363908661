import React from 'react';
import Card from '../../Card';
import DeploymentLocationCreateEditForm from '../../Forms/DeploymentLocationCreateEditForm';

export default props => {
  const deploymentLocationId =
    props &&
    props.match &&
    props.match.params &&
    props.match.params.deploymentLocationId;

  // Body Section of View
  function Body() {
    return <DeploymentLocationCreateEditForm {...props} entityId={deploymentLocationId} entityName={'Deployment Location'}  entityBaseUrl={'deploymentLocation'} />;
  }

  return (
    <Card
      title={(deploymentLocationId ? 'Edit' : 'Add') + ' DeploymentLocation'}
      titleDescription={(deploymentLocationId ? 'Edit' : 'Add') + ' DeploymentLocation'}
      body={Body}
    />
  );
};
