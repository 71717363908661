import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import Login from './Login';
import { AuthContext } from './AuthContext';
import CTAMessage from '../Help/CTAMessage';
import CTAWarn from '../Help/CTAWarn';
import ContentContainer from '../Layout/ContentContainer';

const ProtectedRoleRoute = ({
  component: Component,
  ...rest
}) => {
  const authContext = useContext(AuthContext);

  if(authContext.loggedOut)
  {
    return null
  }

  if (!authContext.user) {
    return <Route render={props => <Login {...props} />} {...rest} />;
  }

  return (
    <Route
      render={props =>
        authContext.user ? (
          <>
            <ContentContainer>
              <Component {...props} user={authContext.user} />
            </ContentContainer>
          </>
        ) : (
          <ContentContainer>
            <CTAMessage
              msg={
                <CTAWarn msg="Unauthorized. Please contact your administrator." />
              }
            />
          </ContentContainer>
        )
      }
      {...rest}
    />
  );
};

export default ProtectedRoleRoute;
