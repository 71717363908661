import React, { useState, useEffect } from "react";
import useProductManagementApi from "../../api/useProductManagementApi";
import Spinner from "../Loaders/Spinner";
import ErrorMessages from "../Notifications/ErrorMessages";
import { Formik, Form } from "formik";
import {
  FormControl,
  StyledField,
  FormActions,
  ErrorMessage
} from "./FormControls";
import Button from "../Button";
import SplashLoader from "../Loaders/SplashLoader";
import confirmDlg from "./ConfirmDialog.js";

export default props => {
  const { entityId, entityName, entityBaseUrl } = props;
  const defaultId = "System Generated";

  const [confirmDlgConfig, setConfirmDlgConfig] = useState({
    show: false,
    title: "",
    description: "",
    action: null
  });

  // Setup needed API Hooks
  // If entity ID is provided get entity
  const url = entityId ? `/${entityBaseUrl}/${entityId}` : null;
  const [
    { loading: getLoading, errors: getError, data: getData },
    getApi
  ] = useProductManagementApi(url, "get", null);

  // Used to create a new one
  const [
    { loading: createLoading, errors: createError, data: createData },
    createApi
  ] = useProductManagementApi();

  // Used to delete an existing one
  const [
    { loading: deleteLoading, errors: deleteError, data: deleteData },
    deleteApi
  ] = useProductManagementApi();

  // Used to edit an existing one
  const [
    { loading: updateLoading, errors: updateError, data: updateData },
    updateApi
  ] = useProductManagementApi();

  useEffect(() => {
    // Return to list view if one was deleted, edited or created
    if (deleteData || updateData || createData) {
      props.history.push(`/${entityBaseUrl}s`);
    }
  }, [deleteData, updateData, createData]);

  if (
    entityId &&
    (getLoading || deleteLoading || updateLoading || createLoading)
  ) {
    const loadingMsg = getLoading
      ? `Fetching ${entityName}`
      : deleteLoading
      ? `Deleting ${entityName}`
      : updateLoading
      ? `Updating ${entityName}`
      : `Creating ${entityName}`;
    return <SplashLoader text={loadingMsg} />;
  }

  return (
    <>
      {confirmDlg(confirmDlgConfig, setConfirmDlgConfig)}

      <Formik
        enableReinitialize={true}
        initialValues={{
          id: getData ? getData.id : defaultId,
          name: getData ? getData.name : "",
          externalId: getData ? getData.externalId : "",
        }}
        validate={values => {
          let errors = {};

          if (!values.name) {
            errors.name = "Required";
          }

          if (!values.externalId) {
            errors.externalId = "Required";
          }

          return errors;
        }}
        onSubmit={values => {
          if (values.id === defaultId) {
            createApi({
              url: `/${entityBaseUrl}`,
              method: "post",
              body: {
                name: values.name,
                externalId: values.externalId
              }
            });
          } else {
            updateApi({
              url: `/${entityBaseUrl}/${values.id}`,
              method: "put",
              body: {
                name: values.name != getData.name ? values.name : null,
                externalId: values.externalId,
              }
            });
          }
        }}
      >
        {({ values, isSubmitting, isValid, isTouched, setFieldValue }) => {
          return (
            <Form>
              {entityId && (
                <FormControl>
                  <StyledField
                    name={`id`}
                    type="text"
                    label="ID"
                    value={values.id}
                    disabled={true}
                  />
                  <ErrorMessage name={`id`} />
                </FormControl>
              )}
              <FormControl>
                <StyledField
                  name={`name`}
                  type="text"
                  label="Name"
                  placeholder="Name"
                  value={values.name}
                />
                <ErrorMessage name={`name`} />
              </FormControl>
              <FormControl>
                <StyledField
                  name={`externalId`}
                  type="text"
                  label="External ID"
                  placeholder="Customer External ID"
                  value={values.externalId}
                />
                <ErrorMessage name={`externalId`} />
              </FormControl>
              <FormActions>
                <FormControl>
                  {getError || deleteError || createError || updateError ? (
                    <ErrorMessages
                      errors={
                        getError
                          ? getError
                          : deleteError
                          ? deleteError
                          : createError
                          ? createError
                          : updateError
                      }
                    />
                  ) : null}

                  {entityId && (
                    <Button
                      list="true"
                      danger="true"
                      type="button"
                      onClick={() => {
                        setConfirmDlgConfig({
                          show: true,
                          title: `Confirm ${entityName} Removal`,
                          description: `Are you sure you wish to remove this ${entityName}?`,
                          action: () => {
                            deleteApi({
                              url: url,
                              method: "delete",
                              body: null
                            });

                            setConfirmDlgConfig({
                              show: false,
                              title: "",
                              description: "",
                              action: null
                            });
                          }
                        });
                      }}
                    >
                      Remove
                    </Button>
                  )}

                  <Button list="true" danger="true" type="reset">
                    {entityId ? "Undo" : "Clear"}
                  </Button>
                  <Button type="submit" disabled={(isSubmitting && !getError && !deleteError && !createError && !updateError) || !isValid}>
                    Submit
                  </Button>
                </FormControl>

                {isSubmitting && !getError && !deleteError && !createError && !updateError ? (
                  <FormControl>
                    <Spinner />
                  </FormControl>
                ) : null}
              </FormActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
