import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import { AuthContext } from "../Auth/AuthContext";
import { NavContext } from "../Layout/NavStateProvider";
import UserMenuItem from "../Layout/Menu/UserMenuItem";
import { MdMenu, MdExitToApp } from "react-icons/md";

const UserMenuButtonNoLink = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: ${props => props.theme.onSecondarySurface};
  border: 0;
  padding: 0.5em;
  border-radius: 4px;
  font-size: 1em;
  width: 100%;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.menuText};
    background: ${props => props.theme.menuHoverActive};
  }
`;

const MenuIcon = styled.div`
  font-size: 1.2em;
  margin-right: ${props => (props.menuState ? ".5rem" : "")};
  color: ${props => props.theme.menuText};
  opacity: 0.5;
  padding-top: 0.1rem;
`;

const UserNav = styled.div`
  margin-top: auto;
  border-top: 1px solid ${props => props.theme.onSecondarySurface};
  text-align: center;
  padding-top: 1rem;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MenuToggle = styled.div`
  display: block;
  color: ${props =>
    props.state ? props.theme.menuText : props.theme.menuText};
  font-size: 1.5rem;
  text-align: center;
  margin-left: ${props => (props.menuState ? "auto" : "")};
  cursor: pointer;
`;

const NavMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

const NavContainer = styled.div`
  display: "flex";
  visibility: visible;
  flex-direction: column;
  position: fixed;
  color: ${props => props.theme.menuText};
  background: ${props => props.theme.headerNav};
  z-index: 3;
  width: ${props => (props.menuState ? "200px" : "60px")};
  transition: all 300ms;
  height: calc(100vh);
  body.home-page & {
    display: none;
    visibility: hidden;
  }
`;

const Wrap = styled.div`
  width: ${props => (props.menuState ? "200px" : "60px")};
  min-width: ${props => (props.menuState ? "200px" : "60px")};
  position: relative;
  z-index: 2;
  transition: all 300ms;

  height: calc(100vh);
`;

const Username = styled.div`
  margin-bottom: 0.5rem;
`;

const Nav = () => {
  const { user, logout } = useContext(AuthContext);
  const { toggleMenu, menuState } = useContext(NavContext);

  if (!user) return null;
  return (
    <Wrap menuState={menuState}>
      <NavContainer menuState={menuState}>
        <NavMenuContainer>
          <>
            <LogoContainer>
              <MenuToggle menuState={menuState} onClick={() => toggleMenu()}>
                <MdMenu />
              </MenuToggle>
            </LogoContainer>

            {<UserMenuItem menuState={menuState} />}
            {user ? (
              <UserNav>
                {menuState && <Username>{user.name}</Username>}
                <UserMenuButtonNoLink onClick={logout}>
                  <MenuIcon menuState={menuState}>
                    <MdExitToApp />
                  </MenuIcon>
                  {menuState && "Logout"}
                </UserMenuButtonNoLink>
              </UserNav>
            ) : null}
          </>
        </NavMenuContainer>
      </NavContainer>
    </Wrap>
  );
};

export default withRouter(Nav);
