import React from 'react';
import Card from '../../Card';
import CustomerCreateEditForm from '../../Forms/CustomerCreateEditForm';

export default props => {
  const customerId =
    props &&
    props.match &&
    props.match.params &&
    props.match.params.customerId;

  // Body Section of View
  function Body() {
    return <CustomerCreateEditForm  {...props} entityId={customerId} entityName={'Customer'}  entityBaseUrl={'customer'} />
  }

  return (
    <Card
      title={(customerId ? 'Edit' : 'Add') + ' Customer'}
      titleDescription={(customerId ? 'Edit' : 'Add') + ' Customer'}
      body={Body}
    />
  );
};
