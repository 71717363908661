import React, { useState, useEffect } from "react";
import useProductManagementApi from "../../api/useProductManagementApi";
import Spinner from "../Loaders/Spinner";
import ErrorMessages from "../Notifications/ErrorMessages";
import { Formik, Form } from "formik";
import {
  FormControl,
  StyledField,
  FormActions,
  ErrorMessage,
  StyledSelect,
  Label
} from "./FormControls";
import Button from "../Button";
import SplashLoader from "../Loaders/SplashLoader";
import ConfirmDlg from './ConfirmDialog'
import LocationTypes from './LocationTypes'

export default props => {
  const { entityId, entityName, entityBaseUrl } = props;
  const defaultId = "System Generated";

  const [confirmDlgConfig, setConfirmDlgConfig] = useState({
    show: false,
    title: "",
    description: "",
    action: null
  });

  // Setup needed API Hooks
  // If entity ID is provided get entity
  const url = entityId ? `/${entityBaseUrl}/${entityId}` : null;
  const [
    { loading: getLoading, errors: getError, data: getData },
    getApi
  ] = useProductManagementApi(url, "get", null);

  const [showConfirm, setShowConfirm] = useState(false);

  // Used to create a new one
  const [
    { loading: createLoading, errors: createError, data: createData },
    createApi
  ] = useProductManagementApi();

  // Used to delete an existing one
  const [
    { loading: deleteLoading, errors: deleteError, data: deleteData },
    deleteApi
  ] = useProductManagementApi();

  // Used to edit an existing one
  const [
    { loading: updateLoading, errors: updateError, data: updateData },
    updateApi
  ] = useProductManagementApi();

  useEffect(() => {
    // Return to list view if one was deleted, edited or created
    if (deleteData || updateData || createData) {
      props.history.push(`/${entityBaseUrl}s`);
    }
  }, [deleteData, updateData, createData]);

  if (
    entityId &&
    (getLoading ||
      deleteLoading ||
      updateLoading ||
      createLoading)
  ) {
    const loadingMsg = getLoading
      ? `Fetching ${entityName}`
      : deleteLoading
      ? `Deleting ${entityName}`
      : updateLoading
      ? `Updating ${entityName}`
      : `Creating ${entityName}`
    return <SplashLoader text={loadingMsg} />;
  }

  return (
    <>
      {ConfirmDlg(confirmDlgConfig, setConfirmDlgConfig)}

      <Formik
        enableReinitialize={true}
        initialValues={{
          id: getData ? getData.id : defaultId,
          name: getData ? getData.name : "",
          locationType: getData && LocationTypes ? LocationTypes.find(l => l.value === getData.type ): "",
        }}
        validate={(values) => {
          let errors = {};

          if (!values.name) {
            errors.name = "Required";
          }

          if(!values.locationType || values.locationType.value ==  'NONE') {
            errors.locationType = "Required";
          }

          return errors;
        }}
        onSubmit={(values) => {
          if (values.id === defaultId) {
            createApi({
              url: `/${entityBaseUrl}`,
              method: "post",
              body: {
                name: values.name,
                type: values.locationType.value
              }
            });
          } else {
            updateApi({
              url: `/${entityBaseUrl}/${values.id}`,
              method: 'put',
              body: {
                name: values.name != getData.name ? values.name : null,
                type: values.locationType.value,
              }
            });
          }
        }}
        >
        {({ values, isSubmitting, isValid, setFieldValue, setFieldTouched }) => {
          return (
            <Form>
              {entityId && (
                <FormControl>
                  <StyledField
                    name={`id`}
                    type="text"
                    label="ID"
                    value={values.id}
                    disabled={true}
                  />
                  <ErrorMessage name={`id`} />
                </FormControl>
              )}
              <FormControl>
                <StyledField
                  name={`name`}
                  type="text"
                  label="Name"
                  placeholder="Name"
                  value={values.name}
                  onBlurr={(e) => {
                    setFieldTouched(`name`, e);
                  }}
                />
                <ErrorMessage name={`name`} />
              </FormControl>

              <FormControl>
                <Label>Location Type</Label>
                <StyledSelect
                  className={`react-select-container`}
                  classNamePrefix={`react-select`}
                  name={`locationType`}
                  placeholder={`Select Location Type`}
                  label="Location Type"
                  value={values.locationType}
                  options={LocationTypes}
                  onBlurr={(e) => {
                    setFieldTouched(`locationType`, e);
                  }}
                  onChange={(e) => {
                    setFieldValue(`locationType`, e)
                  }}
                  menuPortalTarget={document.body}
                />
                <ErrorMessage name={`locationType`} />
              </FormControl>
              <FormActions>
                <FormControl>
                {getError || deleteError || createError || updateError ? (
                    <ErrorMessages
                      errors={
                        getError
                        ? getError
                        : deleteError
                        ? deleteError
                        : createError
                        ? createError
                        : updateError
                      }
                    />
                  ) : null}


                  {entityId && (
                    <Button
                      list="true"
                      danger="true"
                      type="button"
                      onClick={() => {
                        setConfirmDlgConfig({
                          show: true,
                          title: `Confirm ${entityName} Removal`,
                          description: `Are you sure you wish to remove this ${entityName}?`,
                          action: () => {
                            deleteApi({
                              url: url,
                              method: "delete",
                              body: null
                            });
                            setConfirmDlgConfig({
                              show: false,
                              title: "",
                              description: "",
                              action: null
                            });
                          }
                        });
                      }}
                    >
                      Remove
                    </Button>
                  )}

                  <Button list="true" danger="true" type="reset">
                    {entityId ? "Undo" : "Clear"}
                  </Button>

                  <Button type="submit" disabled={(isSubmitting && !getError && !deleteError && !createError && !updateError) || !isValid}>
                    Submit
                  </Button>
                </FormControl>

                {isSubmitting && !getError && !deleteError && !createError && !updateError ? <FormControl><Spinner /></FormControl> : null}

              </FormActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
