import React, { useContext } from "react";
import { Route } from "react-router-dom";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { FlexVertCenterContainer } from "../Layout/Flex/FlexContainers";
import SpinningLoader from "../../components/Loaders/SpinningLoader";
import { AuthContext } from "./AuthContext";

const LoginRedirect = React.memo(function LoginRedirect() {
  const { loginErrors, doLogin, user } = useContext(AuthContext);

  if (!loginErrors && !user) {
    // Set current URL
    const currentURL = new URL(window.location);

    // Set Token
    const hash = currentURL.hash;
    let hashParts = hash.split("&");
    let token = undefined;

    for (let index = 0; index < hashParts.length; index++) {
      if (hashParts[index].startsWith("#access_token")) {
        let pairParts = hashParts[index].split("=");
        if (pairParts.length === 2) {
          token = pairParts[1];
        }
        break;
      }
    }

    // Begin Login
    doLogin(token);
  }
  
  return (
    <Route
      render={props =>
        loginErrors ? (
          <FlexVertCenterContainer col>
            <ErrorMessages {...props} errors={loginErrors} />
          </FlexVertCenterContainer>
        ) : (
          <FlexVertCenterContainer col>
            <SpinningLoader text="Logging In" />
          </FlexVertCenterContainer>
        )
      }
    />
  );
});

export default LoginRedirect;
