import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components/macro';

const ActionsLink = styled(Link)`
  color: ${props => props.theme.onSecondarySurface};
`;

export default props => {
  return <ActionsLink {...props}>{props.children}</ActionsLink>;
};
