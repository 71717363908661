import React from "react";
import ListView from "./ListView";
import { Link } from "react-router-dom";
import * as paths from "../../../common/paths";
import "react-table/react-table.css";
import LocationTypes from '../../Forms/LocationTypes'

export default () => {
  const columnsData = [
    {
      Header: "Id",
      id: "id",
      accessor: d => d.id,
      Cell: data => {
        return (
          <>
            <span data-tip={data.original.id}>
              <Link to={paths.deploymentLocation(data.original.id)}>
                {data.original.id}
              </Link>
            </span>
          </>
        );
      }
    },
    {
      Header: "Name",
      id: "name",
      accessor: d => d.name
    },
    {
      Header: "Type",
      id: "type",
      accessor: d => d.type,
      Cell: data => {
        return (
          <>
            <span>
              {LocationTypes ? LocationTypes.find(lt => lt.value === data.original.type).label : ""}
            </span>
          </>
        );
      }
    }
  ];

  return (
    <ListView
      listType = {"Deployment Location"}
      listTypeCommand = {"deploymentlocation"}
      columnsData={columnsData}
      includeActiveUI={false}
    />
  );
};
