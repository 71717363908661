import React, { useContext } from "react";
import { AiOutlineDingding } from "react-icons/ai";
import { GoOrganization } from "react-icons/go";
import { FaLocationArrow } from "react-icons/fa";
import { GiSubmarine, GiGreekTemple } from "react-icons/gi";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { AuthContext } from "../../Auth/AuthContext";
import * as paths from "../../../common/paths";

const MenuIcon = styled.div`
  font-size: 1.2em;
  margin-right: ${props => (props.menuState ? "10px" : "")};
  color: ${props => props.theme.menuText};
  opacity: 0.5;
`;

const UserMenuButton = styled(NavLink)`
  border-bottom: 1px solid #292849;
  display: flex;
  align-items: center;
  background: transparent;
  color: ${props => props.theme.menuText};
  border: 0;
  justify-content: ${props => (props.menuState ? "" : "center")};
  padding: 0.5em;
  margin: 0.5em;
  margin-right: 0;
  margin-left: 0;
  border-radius: 4px;
  font-size: 1em;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    background: ${props => props.theme.menuHoverActive};
  }
  &.active {
    ${MenuIcon} {
      color: ${props => props.theme.onSecondarySurface};
    }
  }
`;

const NavList = styled.div`
  margin-top: 1rem;
`;

function UserMenuItem({ menuState }) {
  const { user } = useContext(AuthContext);

  return (
    <NavList>
      <UserMenuButton menuState={menuState} to={paths.deployments()}>
        <MenuIcon menuState={menuState}>
          <AiOutlineDingding />
        </MenuIcon>
        {menuState && "Deployment"}
      </UserMenuButton>
      <UserMenuButton menuState={menuState} to={paths.subscriptions()}>
        <MenuIcon menuState={menuState}>
          <GiSubmarine />
        </MenuIcon>
        {menuState && "Subscription"}
      </UserMenuButton>
      <UserMenuButton menuState={menuState} to={paths.subscriptionTemplates()}>
        <MenuIcon menuState={menuState}>
          <GiGreekTemple />
        </MenuIcon>
        {menuState && "Subscription Template"}
      </UserMenuButton>
      <UserMenuButton menuState={menuState} to={paths.customers()}>
        <MenuIcon menuState={menuState}>
          <GoOrganization />
        </MenuIcon>
        {menuState && "Customer"}
      </UserMenuButton>
      <UserMenuButton menuState={menuState} to={paths.deploymentLocations()}>
        <MenuIcon menuState={menuState}>
          <FaLocationArrow />
        </MenuIcon>
        {menuState && "Location"}
      </UserMenuButton>
    </NavList>
  );
}

export default UserMenuItem;
