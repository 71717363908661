import React from 'react';
import styled from 'styled-components/macro';

const ActionWrapper = styled.div`
  background: ${props => props.theme.secondarySurface};
  padding: 6px;
  padding-bottom: 2px;
  font-size: 1.5em;
  border-radius: 4px;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  margin-right: 5px;
  color: ${props => props.theme.onSecondarySurface};
  opacity: ${props => (props.isActive ? 1 : props.disabled ? 0.3 : 0.8)};
`;

export default props => {
  return <ActionWrapper {...props}>{props.children}</ActionWrapper>;
};
