import React, { Component } from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.button`
  /* Adapt the colours based on primary prop */
  background: ${props =>
    props.primary
      ? props.theme.onSurfaceLight
      : props.danger
      ? props.theme.danger
      : props.theme.onSurfaceLight};
  color: ${props => props.theme.onSurfaceLightSecondary};
  cursor: pointer;
  font-size: ${props => (props.size === 'small' ? '.8em' : '1rem')};
  width: ${props => (props.span ? '100%' : 'auto')};
  margin-right: ${props => (props.list ? '1em' : '0')};
  padding: 0.5em 1em;
  display: ${props => (props.display ? props.display : '')};
  border: none;
  transition: all ${props => props.theme.transitionSpeed} linear;
  &:hover {
    background: ${props =>
      props.primary
        ? props.theme.surfaceAlt
        : props.danger
        ? props.theme.dangerAlt
        : props.theme.surfaceAlt};
  }
  &:disabled {
    opacity: 0.3;
  }
`;

const ButtonCenter = styled.div`
  width: 100%;
  text-align: center;
`;

export default class Button extends Component {
  render() {
    return this.props.center ? (
      <ButtonCenter>
        <ButtonContainer {...this.props} />
      </ButtonCenter>
    ) : (
      <ButtonContainer {...this.props} />
    );
  }
}
