import React from "react";
import Button from "../Button";
import Modal from "../Modal";

export default function confirmDlg(confirmDlgConfig, setConfirmDlgConfig) {
    return confirmDlgConfig.show ? (
      <Modal
        title={confirmDlgConfig.title}
        hide={() => {
          setConfirmDlgConfig({
            show: false,
            title: "",
            description: "",
            action: null,
          });
        }}
      >
        <p>{confirmDlgConfig.description}</p>
        <div>
          <Button
            type="button"
            list="true"
            danger="true"
            onClick={confirmDlgConfig.action}
          >
            Yes
          </Button>
          <Button
            type="button"
            onClick={() => {
              setConfirmDlgConfig({
                show: false,
                title: "",
                description: "",
                action: null
              });
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    ) : null;
};
