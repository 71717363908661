import React from "react";
import ListView from "./ListView";
import { Link } from "react-router-dom";
import * as paths from "../../../common/paths";
import "react-table/react-table.css";

export default () => {
  const columnsData = [
    {
      Header: "Id",
      id: "id",
      accessor: d => d.id,
      Cell: data => {
        return (
          <>
            <span data-tip={data.original.id}>
              <Link to={paths.customer(data.original.id)}>
                {data.original.id}
              </Link>
            </span>
          </>
        );
      }
    },
    {
      Header: "Name",
      id: "name",
      accessor: d => d.name
    },
    {
      Header: "External Id",
      id: "externalId",
      accessor: d => d.externalId
    }
  ];

  return (
    <ListView
      listType={"Customer"}
      listTypeCommand={"customer"}
      columnsData={columnsData}
      includeActiveUI={false}
    />
  );
};
