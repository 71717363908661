export function removeCurrentSessionUsersArray(user, users) {
  const usersWithoutCurrentSession = users.filter(
    u => Number(u.id) !== Number(user.id)
  );
  return usersWithoutCurrentSession;
}

export function sortColumnNumber(column1, column2) {
  let order = 1;
  if (column1.columnNumber > column2.columnNumber) order = 1;
  if (column1.columnNumber === column2.columnNumber) order = 0;
  if (column1.columnNumber < column2.columnNumber) order = -1;
  return order;
}

//TODO: Remove if unnecessary for permissions
export function removeCurrentSessionUsersPermissions(user, permissions) {
  const usersWithoutCurrentSession = permissions.filter(
    u => Number(u.accessingUserId) !== Number(user.id)
  );
  return usersWithoutCurrentSession;
}

export function filterMethod(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
    : true;
}

export function filterDisabled(results) {
  if (!results) return null;
  return results.filter(item => !item.disabledOn);
}
