import React from 'react';
import ReactTable from 'react-table';
import styled, { withTheme } from 'styled-components';
import { filterMethod } from '../../common/helpers/util';
import ReactTooltip from "react-tooltip";

export function makePlaceholderFilter(placeholder) {
  return ({ filter, onChange }) => (
    <input
      type="text"
      placeholder={placeholder}
      style={{
        width: '100%'
      }}
      value={filter ? filter.value : ''}
      onChange={event => onChange(event.target.value)}
    />
  );
}

const StyledTable = styled(ReactTable)`
  .ReactTable {
  }
  .ReactTable .rt-thead {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ReactTable .rt-table {
    overflow: hidden;
  }

  &&&.ReactTable.-striped .rt-tr.-odd {
    background: ${props =>
      `rgba(${props.theme.overlayBg}, ${props.theme.overlayOpacity})`};
  }

  &&&.ReactTable .rt-thead .rt-th {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  &&&.ReactTable .-pagination .-btn {
    color: ${props => props.theme.onSurface};
    background: ${props => props.theme.secondarySurface};
  }

  &&&.ReactTable .-pagination .-btn:not([disabled]):hover {
  }

  &&&.ReactTable .-pagination .-btn[disabled] {
    color: ${props => props.theme.onSurface};
    opacity: 0.5;
  }

  &&&.ReactTable .-pagination input {
    border: none;
    background: ${props => props.theme.secondarySurface};
    color: ${props => props.theme.onSurface};
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
  }

  &&&.ReactTable .-pagination select {
    border: none;
    background: ${props => props.theme.secondarySurface};
    color: ${props => props.theme.onSurface};
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
  }

  &&&.ReactTable .rt-thead.-filters .rt-th {
    padding: 1em;
  }

  &&&.ReactTable .rt-thead.-filters input {
    border: none;
    background: ${props => props.theme.secondarySurface};
    color: ${props => props.theme.onSurface};
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
  }

  &&&.ReactTable .-loading {
    background: none;
  }

  &&&.ReactTable .-loading div {
    color: ${props => props.theme.onSurface};
  }
`;

const Table = ({ data, columnsData, defaultSorted, ...props }) => {
  const tableData = (data && data.length && data) || [];

  const defaultPageSize = props.defaultPageSize ? props.defaultPageSize : 20;

  return (
    <>
    <ReactTooltip place="right" type="dark" effect="float" />
    <StyledTable
      data={tableData}
      getProps={() => {
        return { style: { border: 'none' } };
      }}
      getTheadProps={() => {
        return {
          style: {
            boxShadow: 'none',
            borderBottom: `solid 2px ${props.theme.surfaceAlt}`,
            background: props.theme.secondarySurface,
            color: props.theme.onSurface
          }
        };
      }}
      getTheadThProps={() => {
        return { style: { outline: 0, padding: '1em' } };
      }}
      getTheadTrProps={() => {
        return { style: { textAlign: 'left' } };
      }}
      getTableProps={() => {
        return { style: { border: 0 } };
      }}
      getTdProps={() => {
        return {
          style: {
            padding: '1.2em',
            color: props.theme.onSurface,
            fontSize: '.9em',
            alignSelf: 'center',
            alignItems: 'center'
          }
        };
      }}
      getNoDataProps={() => {
        return {
          style: {
            background: props.theme.secondarySurface,
            color: props.theme.onSecondarySurface
          }
        };
      }}
      showPagination={tableData.length > defaultPageSize}
      columns={columnsData}
      pageSize={
        props.defaultPageSize
          ? props.defaultPageSize
          : tableData.length > 20
          ? 20
          : !tableData.length
          ? 4
          : tableData.length
      }
      defaultFilterMethod={filterMethod}
      className="-striped"
      defaultSorted={defaultSorted}
      {...props}
    />
    </>
  );
};

export default withTheme(Table);
