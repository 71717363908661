export function customers() {
  return `/customers`;
}

export function customer(customerId) {
  return `/customer/${customerId}`;
}

export function customerWithParam() {
  return `/customer/:customerId`;
}

export function createCustomer() {
  return `/customer/create`;
}

export function deploymentLocations() {
  return `/deploymentLocations`;
}

export function deploymentLocation(deploymentLocationId) {
  return `/deploymentLocation/${deploymentLocationId}`;
}

export function deploymentLocationWithParam() {
  return `/deploymentLocation/:deploymentLocationId`;
}

export function createDeploymentLocation() {
  return `/deploymentLocation/create`;
}

export function deployments() {
  return `/deployments`;
}

export function deployment(deploymentId) {
  return `/deployment/${deploymentId}`;
}

export function deploymentWithParam() {
  return `/deployment/:deploymentId`;
}

export function createDeployment() {
  return `/deployment/create`;
}

export function subscriptions() {
  return `/subscriptions`;
}

export function subscription(subscriptionId) {
  return `/subscription/${subscriptionId}`;
}

export function subscriptionWithParam() {
  return `/subscription/:subscriptionId`;
}

export function createSubscription() {
  return `/subscription/create`;
}

export function subscriptionTemplates() {
  return `/subscriptionTemplates`;
}

export function subscriptionTemplate(subscriptionTemplateId) {
  return `/subscriptionTemplate/${subscriptionTemplateId}`;
}

export function subscriptionTemplateWithParam() {
  return `/subscriptionTemplate/:subscriptionTemplateId`;
}

export function createSubscriptionTemplate() {
  return `/subscriptionTemplate/create`;
}
