import React from "react";
import ListView from "./ListView";
import { Link } from "react-router-dom";
import * as paths from "../../../common/paths";
import "react-table/react-table.css";

export default () => {
  const columnsData = [
      {
        Header: "Id",
        id: "id",
        accessor: d => d.id,
        Cell: data => {
          return (
            <>
              <span data-tip={data.original.id}>
                <Link to={paths.subscription(data.original.id)}>
                  {data.original.id}
                </Link>
              </span>
            </>
          );
        }
      },
      {
        Header: "Name",
        id: "name",
        accessor: d => d.name
      },
      {
        Header: "Template Id",
        id: "templateId",
        accessor: d => d.templateId,
        Cell: data => {
          return (
            <>
              <span data-tip={data.original.templateId}>
                <Link to={paths.subscriptionTemplate(data.original.templateId)}>
                  {data.original.templateId}
                </Link>
              </span>
            </>
          );
        }
      },
      {
        Header: "Customer Id",
        id: "templateId",
        accessor: d => d.customerId,
        Cell: data => {
          return (
            <>
              <span data-tip={data.original.customerId}>
                <Link to={paths.customer(data.original.customerId)}>
                  {data.original.customerId}
                </Link>
              </span>
            </>
          );
        }
      },
      {
        Header: "Allotment",
        id: "dataSourceAllotment",
        accessor: d => d.dataSourceAllotment
      },
      {
        Header: "Max Allotment",
        id: "maxAllotment",
        accessor: d => d.maxAllotment
      },
      {
        Header: "Engagement Threshold",
        id: "enhanceCustomerEngagementThreshold",
        accessor: d => d.enhanceCustomerEngagementThreshold
      },
      {
        Header: "Max Dur.",
        id: "extendedAllotmentMaxDuration",
        accessor: d => d.extendedAllotmentMaxDuration
      },
      {
        Header: "Active",
        id: "active",
        accessor: d => d.active,
        Cell: data => {
          return <>{data.original.active ? "True" : "False"}</>;
        }
      },
      {
        Header: "Cost",
        id: "cost",
        accessor: d => d.cost
      },
    ];

  return (
    <ListView
      listType = {"Subscription"}
      listTypeCommand = {"subscription"}
      columnsData={columnsData}
      includeActiveUI={true}
    />
  );
};
