import React from "react";
import ListView from "./ListView";
import { Link } from "react-router-dom";
import * as paths from "../../../common/paths";
import "react-table/react-table.css";

export default () => {
  const columnsData = [
    {
      Header: "Id",
      id: "id",
      accessor: d => d.id,
      Cell: data => {
        return (
          <>
            <span data-tip={data.original.id}>
              <Link to={paths.deployment(data.original.id)}>
                {data.original.id}
              </Link>
            </span>
          </>
        );
      }
    },
    {
      Header: "Name",
      id: "name",
      accessor: d => d.name
    },
    {
      Header: "Url",
      id: "url",
      accessor: d => d.url
    },
    {
      Header: "Subscription ID",
      id: "subscriptionId",
      accessor: d => d.subscriptionId,
      Cell: data => {
        return (
          <>
            <span data-tip={data.original.subscriptionId}>
              <Link to={paths.subscription(data.original.subscriptionId)}>
                {data.original.subscriptionId}
              </Link>
            </span>
          </>
        );
      }
    },
    {
      Header: "Location ID",
      id: "locationid",
      accessor: d => d.locationId,
      Cell: data => {
        return (
          <>
            <span data-tip={data.original.locationId}>
              <Link to={paths.deploymentLocation(data.original.locationId)}>
                {data.original.locationId}
              </Link>
            </span>
          </>
        );
      }
    }
  ];

  return (
    <ListView
      listType = {"Deployment"}
      listTypeCommand = {"deployment"}
      columnsData={columnsData}
      includeActiveUI={false}
    />
  );
};
