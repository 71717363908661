import React from "react";
import Card from "../../Card";
import SubscriptionTemplateCreateEditForm from "../../Forms/SubscriptionTemplateCreateEditForm";

export default props => {
  const subscriptionTemplateId =
    props &&
    props.match &&
    props.match.params &&
    props.match.params.subscriptionTemplateId;

  // Body Section of View
  function Body() {
    return (
      <SubscriptionTemplateCreateEditForm
        {...props}
        entityId={subscriptionTemplateId}
        entityName={"Subscription Template"}
        entityBaseUrl={"subscriptionTemplate"}
      />
    );
  }

  return (
    <Card
      title={
        (subscriptionTemplateId ? "Edit" : "Add") + " Subscription Template"
      }
      titleDescription={
        (subscriptionTemplateId ? "Edit" : "Add") + " Subscription Template"
      }
      body={Body}
    />
  );
};
