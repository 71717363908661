import React from 'react';
import styled from 'styled-components';

const ErrorMessagesContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  background: ${props => props.theme.surface};
`;

const ErrorHeader = styled.div`
  background: ${props => props.theme.danger};
  padding: 10px;
  color: ${props => props.theme.onSurfaceLightSecondary};
  font-weight: bold;
`;

const ErrorMessagesInnerContainer = styled.div`
  border: solid ${props => props.theme.danger} 4px;
  padding: 10px;
`;

const MessageWrap = styled.div`
  word-break: break-word;
`;

export default function ErrorMessages({ errors }) {
  if (!errors) return null;
  return (
    <ErrorMessagesContainer>
      <ErrorHeader>Errors Found</ErrorHeader>
      <ErrorMessagesInnerContainer>
        {errors.map((error, i) => {
          return <MessageWrap key={i}>{error.message}</MessageWrap>;
        })}
      </ErrorMessagesInnerContainer>
    </ErrorMessagesContainer>
  );
}
