import React from "react";
import styled from "styled-components/macro";

const ContentContainer = styled.div`
  padding: 0.8rem;
  background: ${props => props.theme.background};
  height: 100%;
`;

export default props => {
  return <ContentContainer>{props.children}</ContentContainer>;
};
