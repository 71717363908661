import { useEffect, useReducer, useState } from "react";
import Cookies from "js-cookie";

function getSecuredToken() {
  return Cookies.get("token");
}

// Reducer States for this component, Details what part of the state updates pending dispatch
const reducer = (state, action) => {
  switch (action.type) {
    // First State called when a query is passed to the component.
    default:
      return {
        ...state,
        loading: true,
        errors: null
      };
    // State dispatched after a query has been completed with a valid response
    // We handle error's here as well for partially incomplete data.
    case "FETCH_COMPLETE":
      return {
        ...state,
        data: action.payload,
        errors: null,
        loading: false
      };
    // State where the query has failed, this also handles server error state
    case "FETCH_FAILURE":
      return {
        ...state,
        loading: false,
        errors: action.payload
          ? [{ message: action.payload }]
          : [{ message: "Error Occurred" }]
      };
  }
};

export default function useProductManagementApi(url = null, method = null, body = null)
{
  const [params, setParams] = useState({ url, method, body });

  const [state, dispatch] = useReducer(reducer, {data: null, errors: null, loading: false});

  useEffect(() => {
    let url = params.url || null;

    if(url != null)
    {
      url = '/api' + params.url;
    }
    const method = params.method || null;
    const body = params.body || undefined;

    if (url === null || url === undefined) {
      return;
    }

    let abortController = new AbortController();

    (async () => {
      let data = null;

      //Create the actual fetch
      try {
        dispatch({
          type: "INIT_FETCH",
          payload: data
        });

        let token = "Bearer " + getSecuredToken();

        var header = new Headers();
        header.append("Content-Type", "application/json");
        header.append("Accept", "application/json");
        header.append("Authorization", token);

        const response = await fetch(url, {
          method: method,
          headers: header,
          body: JSON.stringify(body),
          signal: abortController.signal
        });

        // Check for json type in the content type to handle the two types of responses
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.indexOf("application/json") !== -1) {
          data = await response.json();
        } else if(contentType) {
          data = await response.text();
        }
        else
        {
          data = "No content";
        }

        //handle a response
        if (!response.ok || !response.body) {

          if(data && data.message)
          {
            throw data.message;
          }
          else
          {
            throw response.statusText;
          }
        }

        //call the complete function, update the state
        dispatch({
          type: "FETCH_COMPLETE",
          payload: data
        });
      } catch (err) {
        //if the singal has not been aborted (component unmounted) set
        // an error to the current component state
        if (!abortController.signal.aborted)
          dispatch({ type: "FETCH_FAILURE", payload: err });
      }
    })();

    // abort any signal left over on unmount
    return () => {
      abortController.abort();
    };
  }, [params]);

  return [state, setParams];
}
